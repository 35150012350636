export const useRecentlyViewed = () => {
	const products = useCookie<String[]>("recentlyViewedProducts");

	const addRecentlyViewedProduct = (productId: String) => {
		products.value = products.value ? [...new Set([productId, ...products.value])] : [productId];
	};

	return {
		addRecentlyViewedProduct,
		products
	};
};
